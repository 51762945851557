import {ChangeDetectionStrategy, Component, enableProdMode} from '@angular/core';
import { environment } from './environments/environment';
import {bootstrapApplication} from "@angular/platform-browser";
import {providers} from "providers";
import {RouterOutlet} from "@angular/router";

@Component({
  selector: 'logistics-root',
  template: '<router-outlet/>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterOutlet],
})
export class MainComponent {}

if (environment.production) {
  enableProdMode();
}
bootstrapApplication(MainComponent, { providers }).catch((e) => console.error(e));
