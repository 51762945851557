import {environment} from "environments/environment";
import {
  BrowserOptions,
  BrowserTracing,
  createErrorHandler,
  instrumentAngularRouting,
  TraceService
} from "@sentry/angular-ivy";
import {APP_INITIALIZER, ErrorHandler, ModuleWithProviders, NgModule} from "@angular/core";
import {Router} from "@angular/router";

export const createSentryConfig = (enabled: boolean): BrowserOptions => ({
  dsn: environment.sentryDSN,
  environment: environment.production ? 'prod' : 'dev',
  enabled,
  integrations: [
    new BrowserTracing({
      _experiments: {enableInteractions: true},
      routingInstrumentation: instrumentAngularRouting,
      tracePropagationTargets: ['localhost', environment.backend, /^\//],
      shouldCreateSpanForRequest: (url: string) =>
        !url.includes(environment.authServerUrl),
    }),
  ],
  sampleRate: 0.4,
  tracesSampleRate: 0.4,
})

@NgModule()
export class SentryProvidersModuleEmpty {}

@NgModule()
export class SentryModule {
  public static forRoot(enabled: boolean): ModuleWithProviders<SentryModule> {
    return enabled
      ? {
        ngModule: SentryModule,
        providers: [
          {
            provide: ErrorHandler,
            useValue: createErrorHandler({showDialog: false}),
          },
          {provide: TraceService, deps: [Router]},
          {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [TraceService],
            multi: true,
          },
        ],
      }
      : {ngModule: SentryProvidersModuleEmpty, providers: []};
  }
}
